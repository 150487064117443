import * as React from "react";

function SvgIconCatAthletics(props) {
  return (
    <svg viewBox="0 0 64 64" {...props}>
      <defs>
        <clipPath id="ICON_CAT_athletics_svg__a">
          <path fill="none" d="M-225.55 102.97h1855.76v1080H-225.55z" />
        </clipPath>
      </defs>
      <g clipPath="url(#ICON_CAT_athletics_svg__a)">
        <image
          width={2611}
          height={1705}
          transform="matrix(.75 0 0 .67 -322.56 46.51)"
        />
      </g>
      <path
        d="M52.13 27a7.78 7.78 0 01.69 2.67 5.3 5.3 0 000 2c.13.52 0 .58.25.95s.78-1.21.68-1.84a14.9 14.9 0 01-.32-2.13c0-.73.08-2.4-.57-3.29s-1.78-1.8-4.64-2.45c-1.07-.24-1.79-.38-2.28-.45a65.82 65.82 0 00-5.58-1.13c-3.23-.52-13.87-2.46-13.87-2.46-3.33-1.42-6.68.69-8.44 2.95a7.94 7.94 0 00-1.16 2.37 1 1 0 00.08.14 2.26 2.26 0 00.11.22c.05.08.08.16.12.24s.07.15.11.23.07.16.1.23l.09.22a1.46 1.46 0 01.07.19l.14.4-.25-.34c0-.05-.07-.11-.12-.17a1.41 1.41 0 01-.14-.18l-.16-.18a1.54 1.54 0 00-.17-.19l-.16-.19h0c-.69-.72-2-.53-3.67-.28s-1.85 1-1.93 1.75-1.71 2.42-1.63 3a4.59 4.59 0 01-.33 2.4c-.32.4 0 1.45 0 2.1s.58.89 1 1.71.08.78.41 1.18 1.19.43 1.19.43c.25.69 1.82-.13 2 .86a5.94 5.94 0 002.29 2.64c.69.44 1.64-1.27 2.25-1.88s.69-1.34 1.31-1.54 3.94 1.61 5.5 2.46a5.3 5.3 0 003.23.43 7.06 7.06 0 01.59 1.36 3.92 3.92 0 01-.6 2.07c-.39.51-.33.66-.33.7s-1.13 1.28-.89 1.28a31.72 31.72 0 003.26-.21 2.16 2.16 0 00.05-.24h.84s.43-.39.39-.77a1.46 1.46 0 01.22-.77s.56-.08.56-.42S32 41.5 32 41.19s.44-.25 1.13-1c.26-.29.38-.66.54-2.67A4.41 4.41 0 0137 35.09a30.48 30.48 0 005.3-.6 3.48 3.48 0 001.7-.89 25.14 25.14 0 011.29 2.83 6.54 6.54 0 01.5 2.73 7.41 7.41 0 01-1.13 2.71c-.56.5-1.34 1-1.56 1.3s-.46.56-.56.75-1 1.12-.64 1.14 2.88 0 3 0 .14-.85.43-1 .43-.31.62-.21.37-.42.66-1.19a21.78 21.78 0 011.62-3.55c.94-1.61 1.09-1.53.7-2.2-.07-.12-.12-.27.41-.08a15.09 15.09 0 012.38 1.94 5.68 5.68 0 01.84 3.06c.06.65-.94 1.63-1 1.83s-1.13 1.78-.81 1.78c.64 0 2.57-.11 2.92-.17s.27-.13.31-.4.29-.31.14-.56 0-.9.52-.79c.69.17.41-.5.18-1.48-.34-1.45-1-4.11-1.09-5.28s-.37-1.39-1.25-2.16a2.46 2.46 0 01-.55-.73l-.06-.09-.15-.24-.16-.33a1.08 1.08 0 01-.16-.21l-.08-.21v-.52c.23-.76-.29-1.75-.09-4.77.68-.8.59-.94.85-.45zm-36.64 2.41c-1.27 1.22-1.77-.38-1.27-.64s-.13-.43-.36-1.44c-.48-.65-1.29-2-.4-1.53s3.31 2.39 2 3.61z"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.8}
        fill="none"
      />
    </svg>
  );
}

export default SvgIconCatAthletics;
