import * as React from "react";

function SvgIconCatAlumniOutcomes(props) {
  return (
    <svg viewBox="0 0 64 64" {...props}>
      <defs>
        <clipPath id="ICON_CAT_alumniOutcomes_svg__clip-path">
          <path fill="none" d="M-161.55 102.97h1855.76v1080H-161.55z" />
        </clipPath>
        <style>
          {
            ".ICON_CAT_alumniOutcomes_svg__cls-3{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1px}"
          }
        </style>
      </defs>
      <g
        clipPath="url(#ICON_CAT_alumniOutcomes_svg__clip-path)"
        id="ICON_CAT_alumniOutcomes_svg__BG"
      >
        <image
          width={2611}
          height={1705}
          transform="matrix(.75 0 0 .67 -258.56 46.51)"
        />
      </g>
      <g id="ICON_CAT_alumniOutcomes_svg__EXPORT">
        <path
          className="ICON_CAT_alumniOutcomes_svg__cls-3"
          d="M49.82 37.7a4.2 4.2 0 00-1.3-3.08 11.2 11.2 0 00-4-2.53 10.42 10.42 0 00-7 0 8.67 8.67 0 00-1.42.64l.34.11a13.65 13.65 0 014.84 3.06 5.14 5.14 0 011.59 3.74c0 1.25.07 2.27.08 3.69.62 0 1.25-.06 1.87-.12 1.21-.13 5-.94 5-2v-.1c.07-1.39.04-2.28 0-3.41zM23.17 35.9A13.74 13.74 0 0128 32.84a8.55 8.55 0 00-1.59-.74 10.42 10.42 0 00-7 0 11.2 11.2 0 00-4 2.53 4.2 4.2 0 00-1.3 3.08c0 1.13-.07 2-.07 3.39v.1c0 1.08 3.83 1.89 5 2 .79.08 1.57.12 2.36.14 0-1.44 0-2.45.08-3.71a5.09 5.09 0 011.69-3.73z"
        />
        <path
          className="ICON_CAT_alumniOutcomes_svg__cls-3"
          d="M42.9 39.64a5.14 5.14 0 00-1.59-3.74 13.65 13.65 0 00-4.84-3.06l-.34-.11a12.11 12.11 0 00-3.89-.62 12.37 12.37 0 00-4.2.72 13.74 13.74 0 00-4.84 3.06 5.09 5.09 0 00-1.58 3.74c-.05 1.26-.08 4.23-.08 4.23 0 1.09 1 1.35 1.8 1.65 1.39.49 6.55.79 8.3.79 2.12 0 8.17-.3 9.56-.79.83-.3 1.81-.47 1.81-1.65-.01.01-.07-2.97-.11-4.22zM42.62 19.88a5.38 5.38 0 00-4.85.85 6.62 6.62 0 01.95 4.66 9.26 9.26 0 01-1.58 4 3.69 3.69 0 00.29.4 4.22 4.22 0 003.65 1.73 4.08 4.08 0 003.3-1.41 7.29 7.29 0 002-4.12 5.42 5.42 0 00-3.76-6.11zM26.32 21.52a4.66 4.66 0 01.25-.5 5.44 5.44 0 00-2-1.14 5.3 5.3 0 00-6.47 2.92c-1.1 2.57-.4 4.9 1.28 7A4.22 4.22 0 0023 31.51a4.08 4.08 0 003.3-1.41 7.13 7.13 0 00.87-1.1 7.52 7.52 0 01-.85-7.48z"
        />
        <path
          className="ICON_CAT_alumniOutcomes_svg__cls-3"
          d="M37.77 20.73a6.52 6.52 0 00-11.2.29 4.66 4.66 0 00-.25.5 7.52 7.52 0 00.85 7.48c.21.32.45.64.7.95a5.13 5.13 0 004.43 2.1 4.91 4.91 0 004-1.71 7.26 7.26 0 00.85-1 9.26 9.26 0 001.58-4 6.62 6.62 0 00-.96-4.61z"
        />
      </g>
    </svg>
  );
}

export default SvgIconCatAlumniOutcomes;
